<template>
  <div class="content">
    <div class="content__party">
      <h1 class="title title--big title--color">Мероприятие (ID: {{ $route.params.id }})</h1>
    </div>
    <form method="post" @submit.prevent="checkForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="passport_fms_name">Название <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.name.$error }">
              <input
                id="passport_fms_name"
                type="text"
                class="form-control__input"
                name="passport_fms_name"
                v-model.trim="form.name"
                @change="$v.form.name.$touch()"
                placeholder="Введите кем выдан паспорт"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <div class="form-group__place">
              <label class="checkbox">
                <div class="checkbox__text">Активность</div>
                <input type="checkbox" name="conditions" v-model="form.is_active" />
                <div class="checkbox__checkmark"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button type="submit" class="button" ref="submit" :disabled="$v.form.$error">
          <span>Сохранить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'EventGPB',
  mixins: [ validationMixin ],
  validations: {
    form: {
      name: { required },
    },
  },
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    this.$store.dispatch('admin/cardsGpb/GET_EVENT', this.$route.params.id)
      .then(response => {
        this.form = response.data;
        this.phoneValue = response.data.phone;
      })
      .catch(() => {
        this.$notify({
          type: 'error',
          title: 'Внимание!',
          text: 'Ошибка получения данных заявки'
        });
      });
  },
  methods: {
    checkForm () {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm();
      }
    },
    sendForm () {
      this.$refs.submit.classList.add('preload');
      this.$store.dispatch('admin/cardsGpb/PATCH_EVENT', { id: this.$route.params.id, form: this.form })
        .then(() => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Данные мероприятия успешно сохранены.'
          });
          this.$router.push({ name: 'events-gpb' });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: 'Ошибка сохранения данных о мероприятии'
          });
        });
    },
  }
};
</script>
